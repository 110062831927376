import React from "react";
import { Link } from "react-router-dom";
import { Header, Footer } from "./index";

export default class Program extends React.Component {
  render() {
    return (
      <>
        <Header />
        <main>
          <header className="detail">
            <Link to="/" className="back" data-transition="slide-from-top">
              <h1>back</h1>
            </Link>
            <section>
              <h3 className="badge">Programa de Lealtad</h3>
              <h1>Mecánica del Programa</h1>
            </section>
          </header>
          <div className="content-wrap">
            <div className="content">
              <div
                className="icon bg"
                style={{ margin: "0px auto", display: "block" }}>
                <img
                  src="/img/b_icono_mecanica.svg"
                  style={{
                    width: "50px",
                    paddingLeft: "5px",
                    paddingTop: "4px",
                  }}
                  alt=""
                />
              </div>
              <section style={{ position: "relative" }}>
                <header>
                  <h2>Mecánica del programa</h2>
                  <h4 className="serif">
                    ¡Conocé cómo acumular puntos y canjearlos!
                  </h4>
                </header>
                <section>
                  <h2>Inscribite</h2>
                  <p>Ingresá desde tu celular a la página web <a href="https://puntosni.frescuraquesenota.com">https://puntosni.frescuraquesenota.com</a></p>
                  <p>Ingresá al link “formá parte del plan de lealtad” y registrá tus datos personales.</p>
                  <p>Lee el reglamento y términos de privacidad y dale “check” a las casillas indicando que estás de acuerdo.</p>
                  <p>Cuando finalicés de llenar el formulario, dale click al botón ENVIAR y esperá unos segundos</p>
                  <p>Y finalmente para guardar tu tarjeta de cliente frecuente en el Wallet, debes presionar el botón: “Add to Apple Wallet” si tu celular es iPhone ó “Añadir a WalletPasses” si tu celular es Android.</p>

                  <h2>Acumulá Puntos</h2>
                  <p>
                  Cada vez que realicés compras al detalle en la Agencia Tip Top, presentá tu tarjeta digital de cliente frecuente ó cédula de identidad, antes de realizar el pago de tu compra.
                  </p>
                  <p>
                  El TITULAR recibirá por cada siete córdobas (C$7.00) de compra el equivalente a UN PUNTO FRESCO
                  </p>
                  <p>
                  El muchacho (a) de la caja, escaneará tu tarjeta de cliente frecuente para que se registren los puntos obtenidos. (los verás actualizados en tu tarjeta, en un tiempo máximo de 3 horas)
                  </p>               
                  <h2>Canje de Puntos</h2>
                  <p>
                  El TITULAR debe tener como mínimo quinientos (500) PUNTOS FRESCOS acumulados en su TARJETA DE CLIENTE FRECUENTE para poder optar por su redención. Solamente debes mostrar al muchacho del punto de venta, cuántos puntos tenés y el producto que querés.
                  </p>
                  <p>
                  Si te hacen falta puntos para llevarte un producto, podés pagar la diferencia.
                  </p>
                  <div align="center" style={{ margin: "0px auto" }}>
                    <Link
                      to="/inscribirme"
                      data-transition="slide-left"
                      style={{ color: "#000000" }}>
                      <h3 className="badge">¿Estás listo para unirte?</h3>
                    </Link>
                  </div>
                  <Link
                    to="/que-es"
                    style={{
                      position: "absolute",
                      top: "0px",
                      left: "-30px",
                      transform: "translate(0%, 0%)",
                    }}>
                    <img src="/img/back.svg" style={{ width: "30px" }} alt="" />
                  </Link>
                  <Link
                    to="/inscribirme"
                    style={{
                      position: "absolute",
                      top: "0px",
                      right: "-30px",
                      transform: "translate(0%, 0%)",
                    }}>
                    <img src="/img/next.svg" style={{ width: "30px" }} alt="" />
                  </Link>
                </section>
              </section>
            </div>
          </div>
          <div data-remodal-id="modal">
            <i className="icon bg icon-CommentwithLines"></i>
            <button
              data-remodal-action="close"
              className="remodal-close"></button>
            <h1></h1>
            <p></p>
            <div className="signature center"></div>
          </div>
        </main>
        <Footer />
      </>
    );
  }
}
