import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class Navigation extends Component {
  render() {
    return (
      <>
        <nav className="strokes">
          <img src="/img/logo/logos.png" id="amigoslidesnavi" alt="" />
          <ul id="navigation">
            <li>
              <Link to="/que-es" data-transition="slide-to-top">
                <section>
                  <h1>
                    ¿Qué es
                    <br />
                    Puntos Frescos?
                  </h1>
                  <h5 className="badge-rounded">
                    Conocelo
                    <br />y unite
                  </h5>
                </section>
                <footer>
                  <img
                    src="/img/w_icono_que_es.svg"
                    style={{ width: "50px" }} alt=""
                  />
                  <h5 className="serif"> Conocé el programa</h5>
                  <p>y sus beneficios.</p>
                </footer>
              </Link>
            </li>
            <li>
              <Link to="/mecanica" data-transition="slide-to-top">
                <section>
                  <h1>
                    Mecánica
                    <br />
                    del programa
                  </h1>
                  <h5 className="badge-rounded">
                    Aquí te <br />
                    explicamos todo
                  </h5>
                </section>
                <footer>
                  <img
                    src="/img/w_icono_mecanica.svg"
                    style={{ width: "50px" }} alt=""
                  />
                  <h5 className="serif">Revisa la mecánica</h5>
                  <p>¿Cómo acumular y canjear?</p>
                </footer>
              </Link>
            </li>
            <li>
              <Link to="/inscribirme" data-transition="slide-to-top">
                <section>
                  <h1>
                    Formá parte del
                    <br />
                    Programa de Lealtad
                  </h1>
                  <h5 className="badge-rounded">
                    Registrate y <br />
                    obtené beneficios{" "}
                  </h5>
                </section>
                <footer>
                  <img
                    src="/img/w_icono_inscribirse.svg"
                    style={{ width: "50px" }} alt=""
                  />
                  <h5 className="serif">Da el primer paso</h5>
                  <p>Comienza a ganar</p>
                </footer>
              </Link>
            </li>
            <li>
              <Link to="/consultar-puntos" data-transition="slide-to-top">
                <section>
                  <h1>
                    Consulta
                    <br />
                    de puntos{" "}
                  </h1>
                  <h5 className="badge-rounded">
                    Necesitas tu número
                    <br />
                    de tarjeta{" "}
                  </h5>
                </section>
                <footer>
                  <img
                    src="/img/w_icono_consulta.svg"
                    style={{ width: "50px" }} alt=""
                  />
                  <h5 className="serif">¡Super sencillo! </h5>
                  <p>Canjéalos por premios</p>
                </footer>
              </Link>
            </li>
            <li>
              <Link to="/faqs" data-transition="slide-to-top">
                <section>
                  <h1>
                    Preguntas
                    <br />
                    Frecuentes
                  </h1>
                  <h5 className="badge-rounded">
                    Todo sobre
                    <br />
                    Programa de Lealtad{" "}
                  </h5>
                </section>
                <footer>
                  <img
                    src="/img/w_icono_aclara.svg"
                    style={{ width: "50px" }} alt=""
                  />
                  <h5 className="serif">La respuesta a tus dudas</h5>
                  <p>¡Pregúntanos!</p>
                </footer>
              </Link>
            </li>
            <li>
              <Link to="/contacto" data-transition="slide-to-top">
                <section>
                  <h1>
                    Contacto
                    <br />{" "}
                  </h1>
                  <h5 className="badge-rounded">
                    Escríbenos <br />
                    cuando quieras
                  </h5>
                </section>
                <footer>
                  <img
                    src="/img/w_icono_contacto.svg"
                    style={{ width: "50px" }} alt=""
                  />
                  <h5 className="serif">Se vale preguntar</h5>
                  <p>Respuesta casi inmediata.</p>
                </footer>
              </Link>
            </li>
            <li>
              <a href="https://cargillnic.loymark.com/AvisoPrivacidad.pdf" target="_blank">
                <section>
                  <h1>
                    Aviso
                    <br />
                    de Privacidad
                  </h1>
                  <h5 className="badge-rounded">
                    Nos preocupamos
                    <br />
                    por vos
                  </h5>
                </section>
                <footer>
                  <img src="/img/w_icono_spam.svg" style={{ width: "50px" }} alt=""/>
                  <h5 className="serif">Cuidamos su</h5>
                  <p>Privacidad</p>
                </footer>
              </a>
            </li>
          </ul>
        </nav>
        <div className="overlay"></div>
        <div data-remodal-id="modal">
          <i className="icon bg icon-CommentwithLines"></i>
          <button
            data-remodal-action="close"
            className="remodal-close"></button>
        </div>
      </>
    );
  }
}
