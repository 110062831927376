import React from "react";
import { Link } from "react-router-dom";
import ReactDOM from "react-dom";
import { Header, Footer, Modal } from "./index";
import configData from "../config.json";
import Swal from "sweetalert2/";
import "./css/styles.css";
import SimpleReactValidator from "simple-react-validator";
import "simple-react-validator/dist/locale/es.js";
import { alphanumeric } from "./js/common";

const modalRoot = document.getElementsByTagName("body")[0];
let baseUrl = configData.SERVER_URL;
let emailREST = configData.EmailREST;
let passwordREST = configData.PasswordREST;

export default class Points extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      tarjeta: "",
      pointsAccumulated: 0,
      pointsBonus: 0,
      pointsExpired: 0,
      pointsRedeemed: 0,
      pointsAvailable: 0,
      showModal: false,
    };

    this.validator = new SimpleReactValidator({ locale: "es" });
  }

  componentDidMount() {
    this.getToken();
  }

  handleValueChange = (e) => {
    let { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  getToken = () => {
    fetch(baseUrl + "/login", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify({
        Email: emailREST,
        Password: passwordREST,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.setState({
          token: "Bearer " + data,
        });
      })
      .catch((error) => console.error(error));
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      this.setState({
        showModal: true,
      });

      fetch(baseUrl + "/account/bycardnumber/" + this.state.tarjeta + "/11", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Content-Type",
          Authorization: this.state.token,
        },
      })
        .then((response) =>
          response.json().then((data) => ({
            data: data,
            status: response.status,
          }))
        )
        .then((res) => {
          this.setState({
            showModal: false,
          });

          if (res.status === "404" || res.status === "500") {
            this.setState({
              show: false,
              pointsAccumulated: 0,
              pointsBonus: 0,
              pointsExpired: 0,
              pointsRedeemed: 0,
              pointsAvailable: 0,
            });

            Swal.fire({
              text:
                res.status === "404"
                  ? "La tarjeta ingresada no forma parte del programa"
                  : "Ha ocurrido un error, por favor intente más tarde",
              icon: res.status === "404" ? "info" : "error",
            });
          } else {
            this.setState({
              show: true,
              pointsAccumulated: res.data.pointsAccumulated,
              pointsBonus: res.data.pointsBonus,
              pointsExpired: res.data.pointsExpired,
              pointsRedeemed: res.data.pointsRedeemed,
              pointsAvailable: res.data.pointsAvailable,
            });
          }
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    const lvShow = this.state.show;
    const pointsAccumulated = this.state.pointsAccumulated;
    const pointsBonus = this.state.pointsBonus;
    const pointsExpired = this.state.pointsExpired;
    const pointsAvailable = this.state.pointsAvailable;
    const pointsRedeemed = this.state.pointsRedeemed;
    const showModal = this.state.showModal;

    return (
      <>
        {ReactDOM.createPortal(
          <Modal style={{ opacity: showModal ? "1" : "0" }} />,
          modalRoot
        )}
        <Header />
        <main>
          <header className="detail">
            <Link to="/" className="back" data-transition="slide-from-top">
              <h1>back</h1>
            </Link>
            <section>
              <h3 className="badge">Programa de Lealtad</h3>
              <h1>Consulta de Puntos</h1>
            </section>
          </header>
          <div className="content-wrap">
            <div className="content">
              <div
                className="icon bg"
                style={{ margin: "0px auto", display: "block" }}>
                <img
                  src="/img/b_icono_consulta.svg"
                  alt=""
                  style={{
                    width: "50px",
                    paddingLeft: "5px",
                    paddingTop: "4px",
                  }}
                />
              </div>
              <section style={{ position: "relative" }}>
                <header>
                  <h2>¡Consulta tus puntos!</h2>
                  <h4 className="serif">
                    Sólo ten a la mano tu número de tarjeta Programa de Lealtad
                  </h4>
                </header>
                <form
                  className="form ambiance-html-form"
                  noValidate
                  onSubmit={this.handleSubmit}>
                  <div className="row">
                    <div className="form-group">
                      <input
                        name="tarjeta"
                        type="text"
                        placeholder="Número de tarjeta"
                        maxLength="15"
                        value={this.state.tarjeta}
                        onChange={(e) => this.handleValueChange(e)}
                        onKeyPress={(e) => alphanumeric(e)}
                      />
                      <i className="icon icon-CreditCard"></i>
                      {this.validator.message(
                        "tarjeta",
                        this.state.tarjeta,
                        "required|max:15",
                        { className: "text-danger" }
                      )}
                    </div>
                  </div>

                  <div style={{ textAlign: "center" }}>
                    <button
                      type="submit"
                      value="submit"
                      className="btn"
                      style={{
                        background: "#F4C400",
                        color: "#000000",
                        padding: "10px 30px",
                      }}>
                      Consultar
                    </button>
                  </div>
                </form>
                <br />
                {lvShow && (
                  <ul className="puntos-lista">
                    <li>
                      <div className="top">
                        <h3 className="title">Puntos Acumulados</h3>
                        <h4 className="puntos badge">{pointsAccumulated}</h4>
                      </div>
                    </li>
                    <li>
                      <div className="top">
                        <h3 className="title">Puntos Bonos</h3>
                        <h4 className="puntos badge">{pointsBonus}</h4>
                      </div>
                    </li>
                    <li>
                      <div className="top">
                        <h3 className="title">Puntos Canjeados</h3>
                        <h4 className="puntos badge"> {pointsRedeemed}</h4>
                      </div>
                    </li>
                    <li>
                      <div className="top">
                        <h3 className="title">Puntos Expirados</h3>
                        <h4 className="puntos badge"> {pointsExpired}</h4>
                      </div>
                    </li>
                    <li>
                      <div className="top">
                        <h3 className="title">Puntos Disponibles</h3>
                        <h4 className="puntos badge"> {pointsAvailable}</h4>
                      </div>
                    </li>
                  </ul>
                )}

                <Link
                  to="/inscribirme"
                  style={{
                    position: "absolute",
                    top: "0px",
                    left: "-30px",
                    transform: "translate(0%, 0%)",
                  }}>
                  <img src="/img/back.svg" style={{ width: "30px" }} alt="" />
                </Link>
                <Link
                  to="/faqs"
                  style={{
                    position: "absolute",
                    top: "0px",
                    right: "-30px",
                    transform: "translate(0%, 0%)",
                  }}>
                  <img src="/img/next.svg" style={{ width: "30px" }} alt="" />
                </Link>
              </section>
            </div>
          </div>
          <div data-remodal-id="modal">
            <i className="icon bg icon-CommentwithLines"></i>
            <button
              data-remodal-action="close"
              className="remodal-close"></button>
            <h1></h1>
            <p></p>
            <div className="signature center"></div>
          </div>
        </main>
        <Footer />
      </>
    );
  }
}
