import React from "react";
import { Link } from "react-router-dom";
import { Header, Footer } from "./index";

export default class About extends React.Component {
  render() {
    return (
      <>
        <Header />
        <div id="wrapper">
          <main>
            <header className="detail">
              <Link to="/" className="back" data-transition="slide-from-top">
                <h1>back</h1>
              </Link>
              <section>
                <h3 className="badge">Puntos Frescos</h3>
                <h1>¿Qué es Puntos Frescos?</h1>
              </section>
            </header>
            <div className="content-wrap">
              <div className="content">
                <div
                  className="icon bg"
                  style={{ margin: "0 auto", display: "block" }}>
                  <img
                    src="/img/b_icono_que_es.svg"
                    style={{
                      width: "50px",
                      paddingLeft: "5px",
                      paddingTop: "4px",
                    }}
                    alt="icono que es"
                  />
                </div>
                <section style={{ position: "relative" }}>
                  <header>
                    <h2>¡Bienvenido!</h2>
                    <h4 className="serif">
                      Serás parte de un grupo selecto amantes del Pollo y los
                      Embutidos de carne vacuna, cerdo, pollo y pavo.
                    </h4>
                  </header>
                  <section>
                    <p>
                    Completá tu registro y comenzá a acumular puntos con cada compra.
                    </p>
                    <p>
                    Cada vez que realicés una compra en las Agencias Aquí más Frescos participantes, presentá tu tarjeta de cliente frecuente almacenada en el Wallet de tu celular y El TITULAR recibirá por  siete córdobas (C$7.00) de compra el equivalente a UN PUNTO FRESCO
                    </p>
                    <p>
                    Todos los “Puntos Frescos” que acumulés, los podrás utilizar para llevarte los productos más frescos de las mejores marcas: Tip Top, Cainsa, Cinta Azul, Delicia y Kimby en las Agencias Aquí Más Frescos. Los "Puntos frescos" que ganás con tus compras, tienen una vigencia de un año, así que apresúrate para acumular muchos puntos para que los podás disfrutar con tu familia.
                    </p>
                    <div align="center" style={{ margin: "0 auto" }}>
                      <Link
                        to="inscribirme"
                        data-transition="slide-left"
                        style={{ color: "000000" }}>
                        <h3
                          className="badge"
                          style={{ textAlign: "center", margin: "0 auto" }}>
                          ¡Regístrate!
                        </h3>
                      </Link>
                    </div>
                    <Link
                      to="/contacto"
                      style={{
                        position: "absolute",
                        top: "0px",
                        left: "-30px",
                        transform: "translate(0%, 0%)",
                      }}>
                      <img
                        src="/img/back.svg"
                        style={{ width: "30px" }}
                        alt="back"
                      />
                    </Link>
                    <Link
                      to="/mecanica"
                      style={{
                        position: "absolute",
                        top: "0px",
                        right: "-30px",
                        transform: "translate(0%, 0%)",
                      }}>
                      <img
                        src="/img/next.svg"
                        style={{ width: "30px" }}
                        alt="next"
                      />
                    </Link>
                  </section>
                </section>
              </div>
            </div>
            <div data-remodal-id="modal">
              <i className="icon bg icon-CommentwithLines"></i>
              <button
                data-remodal-action="close"
                className="remodal-close"></button>
            </div>
          </main>
        </div>
        <Footer />
      </>
    );
  }
}
