import React from "react";
import { Header, Footer } from "./index";
import { Link, Redirect, withRouter } from "react-router-dom";

class Wallet extends React.Component {
  render() {
    debugger;
    const walletURL = this.props.location.state !== undefined ? this.props.location.state.url : "";
    const accountNumber = this.props.location.state !== undefined ? this.props.location.state.accountNumber : "";

    return (
      <>
        {walletURL === "" ? <Redirect to="/inscribirme" /> : ""}
        <Header />
        <main>
          <header className="detail">
            <Link to="/" className="back" data-transition="slide-from-top">
              <h1>back</h1>
            </Link>
            <section>
              <h3 className="badge">Programa de Lealtad</h3>
              <h1>Descarga tu Wallet</h1>
            </section>
          </header>
          <div className="content-wrap">
            <div className="content">
              <div
                className="icon bg"
                style={{ margin: "0 auto", display: "block" }}>
                <img
                  src="/img/b_icono_consulta.svg"
                  style={{
                    width: "50px",
                    paddingLeft: "5px",
                    paddingTop: "4px",
                  }} alt=""
                />
              </div>
              <section>
                <header>
                  <h2>¡Registro exitoso!</h2>
                  <h4 className="serif">Aquí esta tu número de tarjeta</h4>
                </header>

                <form
                  className="form ambiance-html-form"
                  action="#"
                  method="post"
                  id="reservation-form">
                  <div className="row">
                    <div className="form-group">
                      <input
                        name="puntos"
                        id="puntos"
                        type="text"
                        placeholder="5244 5588 6655 2211"
                        readonly=""
                        value={accountNumber}
                        style={{ textAlign: "center" }}
                      />
                      <i className="icon icon-CreditCard"></i>
                    </div>
                  </div>
                </form>

                <br />

                <p align="center">
                  <a href={walletURL}>
                    <img
                      src="/img/apple-wallet.png"
                      style={{
                        maxWidth: "200px",
                        margin: "0 auto",
                        display: "block",
                      }} alt=""
                    />
                  </a>
                </p>

                <p align="center">
                  <a href={walletURL}>
                    <img
                      src="/img/walletpasses.png"
                      style={{
                        maxWidth: "200px",
                        margin: "0 auto",
                        display: "block",
                      }} alt=""
                    />
                  </a>
                </p>
              </section>
            </div>
          </div>
          <div data-remodal-id="modal">
            <i className="icon bg icon-CommentwithLines"></i>
            <button
              data-remodal-action="close"
              className="remodal-close"></button>
            <h1></h1>
            <p></p>
            <div className="signature center"></div>
          </div>
        </main>
        <Footer />
      </>
    );
  }
}

export default withRouter(Wallet);
