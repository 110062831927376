import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Header, Footer } from "./index";
import { HashLink } from "react-router-hash-link";

export default class Faqs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: "programa",
    };
  }

  handleValueChange = (val) => {
    this.setState({
      show: val.currentTarget.id,
    });
  };

  render() {
    let show = this.state.show;
    return (
      <>
        <Header />
        <div id="wrapper">
          <main>
            <header className="detail">
              <Link to="/" className="back" data-transition="slide-from-top">
                <h1>back</h1>
              </Link>
              <section>
                <h3 className="badge">Programa de Lealtad</h3>
                <h1>Preguntas Frecuentes</h1>
              </section>
            </header>
            <div className="content-wrap">
              <div className="content">
                <div
                  className="icon bg"
                  style={{ margin: "0 auto", display: "block" }}>
                  <img
                    src="/img/b_icono_aclara.svg"
                    style={{
                      width: "50px",
                      paddingLeft: "5px",
                      paddingTop: "4px",
                    }}
                    alt=""
                  />
                </div>
                <section style={{ position: "relative" }}>
                  <header>
                    <h2>Todas tus dudas resueltas</h2>
                    <h4 className="serif">
                      Si tu duda no esta aquí, por favor escríbenos
                    </h4>
                  </header>
                  <header className="with-subnav">
                    <ul>
                      <li
                        id="programa"
                        className={`btn ${
                          this.state.show === "programa" ? "active" : ""
                        }`}
                        onClick={(val) => this.handleValueChange(val)}>
                        <HashLink
                          to="/faqs#programa"
                          data-transition="slide-left">
                          <h4>Sobre el Programa</h4>
                        </HashLink>
                      </li>
                      <li
                        id="puntos"
                        className={`btn ${
                          this.state.show === "puntos" ? "active" : ""
                        }`}
                        onClick={(val) => this.handleValueChange(val)}>
                        <HashLink
                          to="/faqs#puntos"
                          data-transition="slide-left">
                          <h4>Sobre los puntos</h4>
                        </HashLink>
                      </li>
                      <li
                        id="nosotros"
                        className={`btn ${
                          this.state.show === "nosotros" ? "active" : ""
                        }`}
                        onClick={(val) => this.handleValueChange(val)}>
                        <HashLink
                          to="/faqs#nosotros"
                          data-transition="slide-left">
                          <h4>Otros</h4>
                        </HashLink>
                      </li>
                    </ul>
                  </header>
                  <section>
                    {show === "programa" && (
                      <div id="programa">
                        <h3>¿Qué son los “PUNTOS FRESCOS Tip Top”?</h3>
                        <p>
                        Son los puntos redimibles por productos que acumula el TITULAR de la TARJETA DE CLIENTE FRECUENTE al realizar sus compras en las AGENCIAS TIP TOP.
                        </p>
                        <hr />
                        <h3>¿Quién es el organizador de este programa de lealtad?</h3>
                        <p>
                        Corporación Tip Top S.A con cédula jurídica número J0310000003203 es la Organizadora de la Promoción y la única responsable de la misma ante cualquier tercero.
                        </p>
                        <hr />
                        <h3>¿Quiénes pueden afiliarse al programa de lealtad?</h3>
                        <p>
                        Podrán afiliarse únicamente personas naturales mayores de edad y con documento de identificación válido, vigente y reconocido por el ordenamiento jurídico de Nicaragua. Es responsabilidad del TITULAR tener un teléfono inteligente apto para instalar el WALLET con acceso a internet.
                        </p>
                        <hr />
                        <h3>¿Cuáles beneficios obtengo al ser parte del programa de lealtad “PUNTOS FRESCOS TIP TOP”?</h3>
                        <p>Los clientes que se registren en el programa de lealtad “PUNTOS FRESCOS TIP TOP” podrán obtener:</p>
                        <p>- Tarjeta de cliente frecuente para acumular “PUNTOS FRESCOS TIP TOP” que podrán ser redimidos por productos</p>
                        <p>- Participar en promociones exclusivas.</p>
                        <p>- Recibir información de nuevos productos, descuentos y eventos.</p>
                        <hr />
                        <h3>¿Qué pasa si me desinscribo del programa de lealtad?</h3>
                        <p>
                        Los PUNTOS FRESCOS TIP TOP que no hayás redimido, serán eliminados, y no podrás reclamarlos en productos ni efectivo.
                        </p>
                        <hr />
                        <h3>¿Tiene algún costo ser parte del programa de lealtad “PUNTOS FRESCOS TIP TOP”?</h3>
                        <p>
                        Este es un programa totalmente gratuito para los clientes al detalle de las Agencias Tip Top participantes.
                        </p>
                      </div>
                    )}
                    {show === "puntos" && (
                      <div id="puntos">
                        <h3>¿Qué son los “PUNTOS FRESCOS TIP TOP”?</h3>
                        <p>Son los puntos redimibles por productos que acumula el TITULAR de la TARJETA DE CLIENTE FRECUENTE 
                          al realizar tus compras en las AGENCIAS TIP TOP.</p>
                        <hr />
                        <h3>¿Quién es el organizador de este programa de lealtad?</h3>
                        <p>Corporación Tip Top S.A con cédula jurídica número J0310000003203 es la Organizadora de la Promoción y 
                          única responsable de la misma ante cualquier tercero.</p>
                        <hr />
                        <h3>¿Quiénes pueden afiliarse al programa de lealtad?</h3>
                        <p>Podrán afiliarse únicamente personas naturales mayores de edad y con documento de identificación válido, 
                          vigente y reconocido por el ordenamiento jurídico de Nicaragua. Es responsabilidad del TITULAR tener un 
                          teléfono inteligente apto para instalar el WALLET con acceso a internet.</p>
                        <hr />
                        <h3>¿Cuáles beneficios obtengo al ser parte del programa de lealtad “PUNTOS FRESCOS TIP TOP”?</h3>
                        <p>Los clientes que se registren en el programa de lealtad “PUNTOS FRESCOS TIP TOP” podrán obtener:</p>
                        <p>- Tarjeta de cliente frecuente para acumular “PUNTOS FRESCOS TIP TOP” que podrán ser redimidos por productos</p>
                        <p>- Participás en promociones exclusivas.</p>
                        <p>- Recibís información de nuevos productos, descuentos y eventos.</p>
                        <hr />
                        <h3>¿Qué pasa si me desinscribo del programa de lealtad?</h3>
                        <p>Los PUNTOS FRESCOS TIP TOP que no haya redimido, serán eliminados, y no podrás reclamarlos en productos ni efectivo.</p>
                        <hr />
                        <h3>¿Tiene algún costo ser parte del programa de lealtad “PUNTOS FRESCOS TIP TOP”?</h3>
                        <p>Este es un programa totalmente gratuito para los clientes al detalle de las Agencias Tip Top participantes.</p>
                      </div>
                    )}
                    {show === "nosotros" && (
                      <div id="nosotros">
                         <h3>¿Qué son los “PUNTOS FRESCOS TIP TOP”?</h3>
                        <p>Son los puntos redimibles por productos que acumula el TITULAR de la TARJETA DE CLIENTE FRECUENTE 
                          al realizar tus compras en las AGENCIAS TIP TOP.</p>
                        <hr />
                        <h3>¿Quién es el organizador de este programa de lealtad?</h3>
                        <p>Corporación Tip Top S.A con cédula jurídica número J0310000003203 es la Organizadora de la Promoción y 
                          única responsable de la misma ante cualquier tercero.</p>
                        <hr />
                        <h3>¿Quiénes pueden afiliarse al programa de lealtad?</h3>
                        <p>Podrán afiliarse únicamente personas naturales mayores de edad y con documento de identificación válido, 
                          vigente y reconocido por el ordenamiento jurídico de Nicaragua. Es responsabilidad del TITULAR tener un 
                          teléfono inteligente apto para instalar el WALLET con acceso a internet.</p>
                        <hr />
                        <h3>¿Cuáles beneficios obtengo al ser parte del programa de lealtad “PUNTOS FRESCOS TIP TOP”?</h3>
                        <p>Los clientes que se registren en el programa de lealtad “PUNTOS FRESCOS TIP TOP” podrán obtener:</p>
                        <p>- Tarjeta de cliente frecuente para acumular “PUNTOS FRESCOS TIP TOP” que podrán ser redimidos por productos</p>
                        <p>- Participás en promociones exclusivas.</p>
                        <p>- Recibís información de nuevos productos, descuentos y eventos.</p>
                        <hr />
                        <h3>¿Qué pasa si me desinscribo del programa de lealtad?</h3>
                        <p>Los PUNTOS FRESCOS TIP TOP que no haya redimido, serán eliminados, y no podrás reclamarlos en productos ni efectivo.</p>
                        <hr />
                        <h3>¿Tiene algún costo ser parte del programa de lealtad “PUNTOS FRESCOS TIP TOP”?</h3>
                        <p>Este es un programa totalmente gratuito para los clientes al detalle de las Agencias Tip Top participantes.</p>
                      </div>
                    )}

                    <div align="center" style={{ margin: "0 auto" }}>
                      <Link
                        to="/contacto"
                        data-transition="slide-left"
                        style={{ color: "#000000" }}>
                        <h3 className="badge">Escríbenos</h3>
                      </Link>
                    </div>
                    <Link
                      to="/consultar-puntos"
                      style={{
                        position: "absolute",
                        top: "0",
                        left: "-30px",
                        transform: "translate(0%, 0%)",
                      }}>
                      <img
                        src="/img/back.svg"
                        style={{ width: "30px" }}
                        alt=""
                      />
                    </Link>
                    <Link
                      to="/contacto"
                      style={{
                        position: "absolute",
                        top: "0",
                        right: "-30px",
                        transform: "translate(0%, 0%)",
                      }}>
                      <img
                        src="/img/next.svg"
                        style={{ width: "30px" }}
                        alt=""
                      />
                    </Link>
                  </section>
                </section>
              </div>
            </div>
            <div data-remodal-id="modal">
              <i className="icon bg icon-CommentwithLines"></i>
              <button
                data-remodal-action="close"
                className="remodal-close"></button>
            </div>
          </main>
        </div>
        <Footer />
      </>
    );
  }
}
