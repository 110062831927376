export const lettersOnly = (evt) => {
  const { value } = evt.target;
  const lvValue = { value }.value;
  const aux = lvValue == "" ? 0 : lvValue.length;
  const charCode = evt.which ? evt.which : evt.keyCode;

  // vocales con tildes y ñ
  const array = [193, 201, 205, 209, 211, 218, 225, 233, 237, 243, 250, 241];
  if (array.includes(charCode)) evt.returnValue = true;

  if (
    charCode > 32 &&
    (charCode < 65 ||
      charCode > 122 ||
      (charCode > 90 && charCode < 97) ||
      (charCode < 160 && charCode > 165))
  ) {
    evt.returnValue = false;
    if (evt.preventDefault) evt.preventDefault();
  }

  // evita que el primer caracter sea un espacio
  if (aux == 0 && charCode == 32) {
    evt.returnValue = false;
    if (evt.preventDefault) evt.preventDefault();
  }

  // evita que se permitan 2 espacios seguidos
  let lastChar = lvValue.substr(lvValue.length - 1);
  if (lastChar == " " && charCode == 32) {
    evt.returnValue = false;
    if (evt.preventDefault) evt.preventDefault();
  }
};

export const noSpaces = (evt) => {
  evt = evt ? evt : window.event;
  let charCode = evt.which ? evt.which : evt.keyCode;
  if (charCode == 32) {
    evt.returnValue = false;
    if (evt.preventDefault) evt.preventDefault();
  }
};

export const alphanumeric = (evt) => {
  const charCode = evt.which ? evt.which : evt.keyCode;

  if (
    (charCode >= 48 && charCode <= 57) ||
    (charCode >= 65 && charCode <= 90) ||
    (charCode >= 97 && charCode <= 122)
  )
    return evt.returnValue = true;

  evt.returnValue = false;
  if (evt.preventDefault) evt.preventDefault();
};

export const isNumber = (evt, max) => {
  const { value } = evt.target;
  const lvValue = { value }.value;

  let charCode = evt.which ? evt.which : evt.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    evt.returnValue = false;
    if (evt.preventDefault) evt.preventDefault();
  }

  if (lvValue.length == max) {
    evt.returnValue = false;
    if (evt.preventDefault) evt.preventDefault();
  }
};
